import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Awards } from 'src/components/Awards/Awards'
import { Footer } from 'src/components/Footer/Footer'
import { ProjectsHeader } from 'src/components/Header/ProjectsHeader'
import { Listing } from 'src/components/Listing/Listing'
import { Modal } from 'src/components/Modal/Modal'
import { Section } from 'src/components/Section/Section'
import { motion } from "framer-motion";
import { ProjectModal } from 'src/components/Modal/ProjectModal'
import { BenchModal } from 'src/components/Modal/BenchModal'
import { useInView } from 'react-intersection-observer'
import { Chat } from 'src/components/Chat/Chat'

export const Projects = () => {

  const { pathname } = useLocation()
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])
  
  return (
    <>
      <motion.div
        className='page-overlay' 
        initial={{ x: '0%', transition: { duration: 0.5 } }}
        animate={{ x: '100%', transition: { duration: 0.5 } }}
        exit={{ x: '100%', transition: { duration: 0.5 } }}  
      />
      <motion.div
        className='page-overlay' 
        initial={{ x: '-100%', transition: { duration: 0.5 } }}
        animate={{ x: '-100%', transition: { duration: 0.5 } }}
        exit={{ x: '0%', transition: { duration: 0.5 } }}  
      />
      <ProjectsHeader isHide={inView} />
      <div className='projects'>
        <Listing />
        <Section>
          <Awards />
        </Section>
      </div>
      <Footer node={ref} />
      <ProjectModal />
      <BenchModal />
      {/* <Chat /> */}
    </>
  )
}
