import React, { useEffect } from 'react'
import styles from './Expertise.module.scss'
import { Section } from '../Section/Section'
import { Container } from '../Container/Container'
import { Title } from '../Title/Title'
import { Category } from '../Category/Category'
import Marquee from 'react-fast-marquee'
import { Client } from '../Client/Client'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { PROJECTS } from 'src/routes'
import { TextAnimation } from '../TextAnimation/TextAnimation'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../Button/Button'
import { openModal } from 'src/store/modals/actionCreators'
import classNames from 'classnames'

const clients = [
  {
    src: 'otlk',
    isBig: false,
  },
  {
    src: 'resp',
    isBig: false,
  },
  {
    src: 'magnit',
    isBig: false,
  },
  {
    src: 'novikov',
    isBig: false,
  },
  {
    src: 'mts',
    isBig: false,
  },
  {
    src: 'lukoil',
    isBig: false,
  },
  {
    src: 'geekbrains',
    isBig: false,
  },
  {
    src: 'eifman',
    isBig: true,
  },
  {
    src: 'icecream',
    isBig: true,
  },
  {
    src: 'vysotskaya',
    isBig: true,
  },
  {
    src: 'mmz',
    isBig: true,
  },
  {
    src: 'gmz',
    isBig: true,
  },
]

export const Expertise = () => {

  const { categories } = useSelector(state => state.projects)
  const dispatch = useDispatch()

  const [data, setData] = useState({
    first: [],
    second: []
  })

  useEffect(() => {
    const result = {
      first: [],
      second: []
    }

    categories.forEach((item, i) => {
      if (i > 3) {
        result.second.push(item)
      } else {
        result.first.push(item)
      }
    })

    setData(result)
  }, [categories])

  return (
    <Section id="expertise">
      <Container>
        <div className={styles.row}>
          <div className={styles.col}>
            <Category num="2" text="Экспертиза" isHorizontal={true} />
          </div>
          <div className={styles.col}>
            <TextAnimation>
              <Title extraClass={styles.title}>Мы накопили опыт работы <br />
                с ведущими компаниями <br />
                и брендами из различных <br />
                отраслей
              </Title>
            </TextAnimation>
            <div className={styles.inner}>
              <div className={classNames(styles.button, styles.desktop)}>
                <Button 
                  text={'Отзывы клиентов'} 
                  onClick={() => dispatch(openModal('reviews'))}
                />
              </div>
              <div className={styles.wrapper}>
                <div className={styles.list}>
                  {data.first.map(item => 
                    <div className={styles.item} key={item.text}>
                      <TextAnimation>
                        <Link
                          className={styles.link}
                          to={`${PROJECTS}?filter=${item.translate}`}
                        >
                          <div className={styles.text}>
                            {item.text}
                          </div>
                          <span className={styles.num}>{item.num}</span>
                        </Link>
                      </TextAnimation>
                    </div>
                  )}
                </div>
                <div className={styles.list}>
                  {data.second.map(item => 
                    <div className={styles.item} key={item.text}>
                      <TextAnimation>
                        <Link 
                          className={styles.link} 
                          to={`${PROJECTS}?filter=${item.translate}`}
                        >
                            <div className={styles.text}>
                              {item.text}
                            </div>
                          <span className={styles.num}>{item.num}</span>
                        </Link>
                      </TextAnimation>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Section>
        <Marquee speed={50}>
          {clients.map(item =>
            <Client src={`${item.src}.jpg`} key={item.src} isBig={item.isBig} />
          )}
        </Marquee>
      </Section>
      <div className={classNames(styles.button, styles.mobile)}>
        <Button
          text={'Отзывы клиентов'}
          onClick={() => dispatch(openModal('reviews'))}
        />
      </div>
    </Section>
  )
}
